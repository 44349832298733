import React from "react";
import styled from "styled-components";

const Banner = ({ title, videoSrc, bannerTitle }) => {
    return (
        <>
            <ContactUsBanner>
                <BannerHeadding>
                    <h1>{title}</h1>
                </BannerHeadding>
                <BannerTitle>
                    {bannerTitle}
                </BannerTitle>
                <VideoOverlay>
                    <video autoPlay loop muted>
                        <source src={videoSrc} type="video/mp4" />
                    </video>
                </VideoOverlay>
            </ContactUsBanner>
        </>
    );
};

const ContactUsBanner = styled.div`
    height: 500px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   position: relative;
    overflow: hidden;
    width: 100%;

`;

const BannerHeadding = styled.span`
    font-size: 25px;
    font-weight: 800;
`;

const BannerTitle = styled.span`
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    padding: 0 5vw 5vw;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  video {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`;

export default Banner;
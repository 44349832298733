import ServiceBlock from "../../Home/components/ServiceBlock";
import styled from 'styled-components';

const services = [
    {
        image: '/img/service/img1.jpg',
        digit: '01',
        heading: 'Création de société',
        text: "Avec une expertise approfondie du marché suisse, nous vous aidons à choisir la meilleure structure juridique adaptée à votre projet et nous vous aidons à naviguer dans les exigences administratives du pays pour la création de votre entreprise. Nous réalisons pour vous toutes les démarches légales utiles auprès des autorités et nous vous connectons aux confrères spécialisés si besoin.",
        link: '/',
    },
    {
        image: '/img/service/img7.jpg',
        digit: '02',
        heading: 'Compte bancaire',
        text: "Nous vous simplifions le processus d'ouverture de compte bancaire en Suisse. Banque privée, banque digitale ou banque classique, nous sommes à votre écoute pour vous proposer du conseil et une assistance opérationnelle sur mesure afin de donner à votre entreprise toutes les chances de réussite. Contactez-nous dès aujourd'hui pour démarrer votre projet sur des bases solides en Suisse.",
        link: '/',
    },
    {
        image: '/img/service/img8.jpg',
        digit: '03',
        heading: 'Domiciliation',
        text: "Nous vous proposons un service complet de bureaux partagés à Genève et dans les plus grandes villes de Suisse grâce à notre réseau de partenaires. Ainsi offrez-vous la liberté de développer votre activité depuis les espaces de coworking de votre choix et entendez-vous rapidement sur tout le territoire grâce à une stratégie de domiciliation et d'expansion d’entreprise maîtrisée.",
        link: '/',
    },

];

const services2 = [
    {
        image: '/img/service/img2.jpg',
        digit: '04',
        heading: 'Comptabilité',
        text: "Optimisez la gestion financière de votre entreprise en Suisse avec notre service de comptabilité dédié. Notre équipe expérimentée vous offre des solutions sur mesure, adaptées à vos besoins spécifiques. Grâce à notre expertise approfondie du contexte fiscal suisse, nous vous accompagnons dans la tenue de vos comptes, la gestion de paie et toutes les obligations comptables, vous permettant de vous concentrer sur le développement de votre activité.",
        link: '/',
    },
    {
        image: '/img/service/img6.jpg',
        digit: '05',
        heading: 'Startups',
        text: "Propulsez votre startup vers le succès avec notre accompagnement sur mesure. Nous vous offrons un soutien stratégique et opérationnel pour transformer vos idées en réalité. Grâce à notre expertise et à notre réseau étendu, nous vous aidons à surmonter les défis et à saisir les opportunités du marché. Faites confiance à notre équipe de passionnés pour vous guider à chaque étape de votre parcours entrepreneurial. Avec nous, votre startup est entre de bonnes mains pour atteindre de nouveaux sommets.",
        link: '/',
    },
    {
        image: '/img/service/img3.jpg',
        digit: '06',
        heading: 'Investisseurs',
        text: "Entourés d’un réseau de professionnels qualifiés et expérimentés en conseil en investissement en Suisse, nous vous proposons un accompagnement sur mesure en phase avec vos objectifs financiers. Notre expertise globale vous permettra de maximiser vos rendements.",
        link: '/',
    },

];

const services3 = [
    {
        image: '/img/service/img5.jpg',
        digit: '07',
        heading: 'Résidence',
        text: "Nous vous offrons une assistance complète pour l’obtention de permis de résidence en Suisse. Nous vous conseillons sur la sélection de la région, vous aidons dans la recherche de votre logement et nous réalisons pour vous les procédures administratives indispensables à l’obtention de permis de séjour et de travail. Nous offrons également des services de gestion de propriété pour les clients qui ambitionnent d’investir dans l’immobilier en Suisse.",
        link: '/',
    },
    {
        image: '/img/service/img4.jpg',
        digit: '08',
        heading: 'Digital',
        text: "Propulsez votre startup vers le succès avec notre accompagnement sur mesure en Suisse. Nous vous offrons un accompagnement des plus haut de gamme en matière de prestations digitales. Notre expertise est globale et utilise les outils d’IA en création de site internet, site e-commerce, plateforme e-business et plateforme de tokenisation d’actifs financiers. Notre soutien stratégique et opérationnel vous sera essentiel pour transformer vos idées en réalité et saisir les opportunités du marché. Avec nous, votre startup est entre de bonnes mains pour atteindre de nouveaux sommets.",
        link: '/',
    },
    {
        image: '/img/service/img9.jpg',
        digit: '09',
        heading: 'Family office',
        text: "Entourés de professionnels expérimentés, nous créons pour vous la Family office qui correspond à vos attentes. Nous vous offrons également la possibilité de créer un Private office si vous êtes un particulier avec des hauts revenus. Nos partenaires sont experts en planification fiscale, gestion de patrimoine, planification successorale et gestion de portefeuille. Nous travaillons en étroite collaboration avec vous pour comprendre vos besoins et ainsi vous fournir les meilleures solutions sur mesure.",
        link: '/',
    },

];

const Services = () => {
    return (
        <Container>
                <Grid>
                    {services.map((service, index) => (
                        <ServiceBlock key={index} {...service} />
                    ))}
                </Grid>
                <Grid style={{marginTop:"13vw"}}>
                    {services2.map((service, index) => (
                        <ServiceBlock key={index} {...service} />
                    ))}
                </Grid>
                <Grid style={{marginTop:"13vw"}}>
                    {services3.map((service, index) => (
                        <ServiceBlock key={index} {...service} />
                    ))}
                </Grid>
        </Container>
    );
};

const Container = styled.div`
  margin-left: 2vw;
  margin-right: 2vw;
`;

const Grid = styled.div`
  grid-column-gap: 2vw;
  grid-template-columns: repeat(16, 1fr);
  align-items: flex-start;
  display: grid;
`;

export default Services;
import React, {useContext} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AnimatedCursor from "react-animated-cursor";
import Menu from "../Menu";
import { Stack } from "@mui/material";
import { ThemeContext } from "../../styles/theme";
import Transition from "../Transition";
import LoaderProject from "./section/LoaderProject";
import SecondSection from "./section/SecondSection";
import Footer from "../Footer";



const Service = () => {
    const [{ themeName }] = useContext(ThemeContext);

    return (
        <div className="App">
            <Menu textColor={true}/>
            <React.Fragment>
                <CssBaseline />
                <AnimatedCursor
                    outerStyle={{
                        border: "1px solid var(--cursor-color)",
                    }}
                    innerStyle={{
                        backgroundColor: "var(--cursor-color)",
                    }}
                    innerSize={8}
                    outerSize={35}
                    innerScale={1}
                    outerScale={2}
                    outerAlpha={0}
                    hasBlendMode={true}
                />
                <Stack>
                    <div style={{width: "100%", height: "100vh"}}
                         className={`${themeName} app`}>
                        <section id={'1'}>
                            <LoaderProject/>
                        </section>
                        <section id={'2'} style={{paddingBottom: "12vw", position: "relative"}}>
                            <SecondSection/>
                        </section>
                        <section id={'7'}>
                            <Footer/>
                        </section>
                    </div>
                </Stack>
            </React.Fragment>
        </div>
    );
}

export default Transition(Service);

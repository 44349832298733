import React from 'react';
import { useViewportScroll, motion, useTransform } from "framer-motion";
import VideoFirst from "../components/VideoFirst";
import styled from 'styled-components';

const FirstSection = () => {
    const { scrollY } = useViewportScroll();
    const y1 = useTransform(scrollY, [0, 0], [0, 0]);
    const y2 = useTransform(scrollY, [0, 300], [0, -50]);

    return (
        <Container>
            <Pres style={{ y: y1, x: 0 }}>
                <Title>
                    Swisseva, le partenaire de votre succès !
                </Title>
                <Heading>
                    <div>
                        <div>
                            Expert en création
                        </div>
                    </div>
                    <div>
                        <div>
                            Financement
                        </div>
                    </div>
                    <div>
                        <div>
                            Digitalisation
                        </div>
                    </div>
                </Heading>
                <Quote>
                    Création de société, comptabilité, ouverture de compte en banque, levée de fonds,
                    connexion avec des investisseurs, création de family office, de trust et de private office,
                    mise en relation avec des célébrités, digitalisation de votre entreprise,
                    secrétariat et domiciliation d’entreprise.
                    <Signe/>
                </Quote>
            </Pres>
            <Content style={{y: y2, x: 0}}>
                <VideoFirst/>
                <TextFirst>
                    Swisseva, votre partenaire de confiance pour toutes les étapes clés de votre entreprise.
                    De la création de société à la comptabilité,
                    en passant par l'ouverture de compte en banque et la levée de fonds,
                    nous offrons des solutions complètes pour votre succès. Avec notre expertise en digitalisation,
                    mise en relation avec des investisseurs, domiciliation et services de secrétariat,
                    nous vous aidons à concrétiser vos ambitions entrepreneuriales en Suisse et dans le monde entier.
                </TextFirst>
            </Content>
        </Container>
    );
}

const Container = styled.div`
    padding: 14vw 0 0;
    margin-left: 2vw;
    margin-right: 2vw;
    display: grid;
    grid-column-gap: 2vw;
    grid-template-columns: repeat(16, 1fr);
    align-items: flex-start;
    @media (max-width: 950px) {
        margin-top: 100px;
        padding: 14vw 0 0;
        margin-left: 2vw;
        margin-right: 2vw;
        display: grid;
        grid-column-gap: 2vw;
        grid-template-columns: repeat(16, 1fr);
        align-items: flex-start;
    }
`;

const Pres = styled(motion.div)`
    grid-column: 2 / 16;
    z-index: 3;
    pointer-events: none;
    position: relative;
    @media (max-width: 950px) {
        grid-column: 16 / 2;
    }
`;

const Title = styled.h2`
    font-size: max(2vw, min(17px));
    font-style: italic;
    font-weight: 400;
    line-height: 1em;
    margin-bottom: -4vw;
    @media (max-width: 950px) {
        margin-bottom: 50px;
    }
`;

const Heading = styled.h1`
    font-size: 9vw;
    font-weight: 300;
    line-height: 9vw;
    margin-left: -0.5vw;
    letter-spacing: 5px;
`;

const Quote = styled.blockquote`
    font-size: max(1vw, min(17px));
    margin: 3vw 0 2vw;
    width: 17vw;
    letter-spacing: 0.1em;
    @media (max-width: 950px) {
        width: 80vw;
    }
`;

const Content = styled(motion.div)`
    grid-column: 9 / 17;
    margin-top: -45vw;
    @media (max-width: 950px) {
        grid-column: 16 / 2;
        margin-top: 50px;
    }
`;

const TextFirst = styled.p`
    margin: 3vw 0 2vw;
    width: 35vw;
    letter-spacing: 0.1em;
    font-size: max(1vw, min(17px));
    @media (max-width: 950px) {
        width: 80vw;
    }
`;

const Signe = styled.hr`
    border: none;
    border-top: 3px double #333;
    color: #333;
    left: 0;
    height: 5px;
    margin-top: 1.25vw;
    width: 6vw;
    margin-right: 100%;
`;

export default FirstSection;
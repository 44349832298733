import classes from "./Footer.module.css";
import {
    FaYoutube,
    FaLinkedinIn,
    FaFacebook,
    FaInstagram,
    FaTiktok,
    FaTelegramPlane,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import styled from "styled-components";
import React from "react";
import {motion} from "framer-motion";

const Footer = () => {
    const image = '/img/suisse.png';

    return (
        <div>
            <footer>
                <div className={classes.branch}>
                    <div className={classes.brancH2}>
                        <Logo style={{transition: 'transform 0.5s ease-out, opacity 0.3s ease-out'}}>
                            Swisseva
                        </Logo>
                        <LogoImage style={{
                            backgroundImage: `url(${image})`,
                        }}/>
                    </div>
                    <div className={classes.branch_list}>
                        <SpanAccueil href="/" className={classes.networks_a}
                                     style={{textDecoration: "none"}}>Accueil</SpanAccueil>
                        <a href="/Service" className={classes.networks_a} style={{textDecoration: "none"}}>Services</a>
                        <a href="/expertise" className={classes.networks_a} style={{textDecoration: "none"}}>Expertise</a>
                    </div>
                </div>
                <div className={classes.lower}>
                    <section className={classes.about}>
                        <h2>A propos</h2>
                        <p className="text-justify">
                            Swisseva SA,
                            <br/>
                            <br/>
                            Domicilé:
                            <br/>
                            <br/>
                            <a href="https://maps.app.goo.gl/zvDy8FFS1YxTSGTg7" className={classes.link_a}
                               style={{textDecoration: "none"}}>Place Cornavin 14, 1201 Genève</a>
                            <br/>
                            <br/>
                            Email:
                            <br/>
                            <br/>
                            <a href="mailto:contact@swisseva.com" className={classes.link_a}
                               style={{textDecoration: "none"}}>contact@swisseva.com</a>
                            <br/>
                            <br/>
                            Mobile/WhatsApp business:
                            <br/>
                            <br/>
                            <a href="tel:+41767860222" className={classes.link_a}
                               style={{textDecoration: "none"}}>+41767860222</a>
                            <br/>
                            <br/>
                            Standard:
                            <br/>
                            <br/>
                            <a href="tel:+41227313131" className={classes.link_a}
                               style={{textDecoration: "none"}}>+41227313131</a>
                            <br/>
                            <br/>
                            Le nom de la société provient de la fusion entre Suisse en anglais et de eva.
                            Il symbolise l’idée de la créer et d’entreprendre de façon générale en Suisse.
                            <br/>
                            <br/>
                            Société enregistrée au registre du commerce de Genève en Suisse:
                            <br/>
                            CHE-397.714.671
                        </p>
                    </section>
                    <section className={classes.networks}>
                        <h2>Nos réseaux sociaux</h2>
                        <div className={classes.networks_container}>
                            <a href="https://www.youtube.com/@Swisseva_ch" className={classes.networks_a}>
                                <Youtube/>
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=61560048449021"
                               className={classes.networks_a}>
                                <Facebook/>
                            </a>
                            <a href="https://twitter.com/swisseva_ch" className={classes.networks_a}>
                                <Twitter/>
                            </a>
                            <a href="https://www.instagram.com/swisseva_geneve/" className={classes.networks_a}>
                                <Instagram/>
                            </a>
                            <a href="https://www.linkedin.com/company/swisseva" className={classes.networks_a}>
                                <Linkedin/>
                            </a>
                            <a href="https://www.tiktok.com/@swisseva_ch" className={classes.networks_a}>
                                <Tiktok/>
                            </a>
                            <a href="https://t.me/swisseva_group" className={classes.networks_a}>
                                <Telegram/>
                            </a>
                        </div>
                    </section>
                </div>
                <div className={classes.branch}>
                    <div className={classes.branch_list}>
                    <p className={classes.p_footer}>© 2024, Swisseva SA</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

const Youtube = styled(FaYoutube)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Facebook = styled(FaFacebook)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Twitter = styled(FaXTwitter)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Linkedin = styled(FaLinkedinIn)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Instagram = styled(FaInstagram)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Tiktok = styled(FaTiktok)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Telegram = styled(FaTelegramPlane)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Logo = styled(motion.div)`
    font: 2rem 'DeckerB', sans-serif;
    font-weight: 900;
    font-size: 45px;
`;

const LogoImage = styled(motion.img)`
    min-width: 37px;
    min-height: 37px;
    background-size: cover;
    background-position: center;
  transition: transform 0.5s;
    margin-left: 15px;
`;

const SpanAccueil = styled.a`
    @media (max-width: 500px) {
        display: none;
    }
`

export default Footer;

import React from "react";
import styled from "styled-components";

const ButtonContact = ({ borderColor, textColor, keyColor }) => {
    return (
        <ContactButton
            borderColor={borderColor}
            textColor={textColor}
            keyColor={keyColor}
            href="https://calendar.app.google/g9PC6S1a3vLsuGu27"
        >
            <TopKey keyColor={keyColor} />
            <TextContact textColor={textColor}>Prise de rendez vous</TextContact>
            <BottomKey keyColor={keyColor} />
            <BottomKey2 keyColor={keyColor} />
        </ContactButton>
    );
};

const ContactButton = styled.a`
    background-color: transparent;
    border: 2px solid ${({ borderColor }) => borderColor};
    border-radius: 0;
    box-sizing: border-box;
    color: ${({ textColor }) => textColor};
    display: inline-block;
    padding: 1.25em 2em;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    &::before{
        content: " ";
        width: 1.5625rem;
        height: 2px;
        background: ${({ borderColor }) => borderColor};
        top: 50%;
        left: 1.5em;
        position: absolute;
        transform: translateY(-50%);
        transform-origin: center;
        transition: background 0.3s linear, width 0.3s linear;
    }
    &:hover {
        color: #d91a27;
        border: 2px solid #d91a27;
    }
    &:hover::before {
        width: 0.9375rem;
        background: #d91a27;
    }
`;

const TextContact = styled.span`
    font-size: 1.125em;
    line-height: 1.33333em;
    padding-left: 2em;
    display: block;
    text-align: left;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    text-decoration: none;
    color: ${({ textColor }) => textColor};

    ${ContactButton}:hover & {
        color: #d91a27;
        padding-left: 1.5em;
    }
`;

const TopKey = styled.span`
    height: 2px;
    width: 1.5625rem;
    top: -2px;
    left: 0.625rem;
    position: absolute;
    background: ${({ keyColor }) => keyColor};
    transition: width 0.5s ease-out, left 0.3s ease-out;

    ${ContactButton}:hover & {
        left: -2px;
        width: 0px;
    }
`;

const BottomKey = styled.span`
    height: 2px;
    width: 1.5625rem;
    right: 1.875rem;
    bottom: -2px;
    position: absolute;
    background: ${({ keyColor }) => keyColor};
    transition: width 0.5s ease-out, right 0.3s ease-out;

    ${ContactButton}:hover & {
        right: 0;
        width: 0;
    }
`;

const BottomKey2 = styled.span`
    height: 2px;
    width: 0.625rem;
    right: 0.625rem;
    bottom: -2px;
    position: absolute;
    background: ${({ keyColor }) => keyColor};
    transition: width 0.5s ease-out, right 0.3s ease-out;
    ${ContactButton}:hover & {
        right: 0;
        width: 0;
    }
`;

export default ButtonContact;
import * as React from 'react';
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import {ButtonMenuToggle} from "./ButtonMenuToggle";

const ButtonDrawer = () => {
    const containerRef = useRef(null);
    const [isOpen, toggleOpen] = useCycle(false, true);

    return (
        <div>
            <motion.nav
                initial={false}
                animate={isOpen? "open" : "closed"}
                ref={containerRef}
            >
                <ButtonMenuToggle toggle={toggleOpen} />
            </motion.nav>
        </div>
    );
};

export default ButtonDrawer;
import * as React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const Path = props => (
    <motion.path
        fill="transparent"
        strokeWidth="3"
        stroke={props.stroke}
        strokeLinecap="round"
        {...props}
    />
);

export const ButtonMenuToggle = ({ toggle }) => {
    const [isToggled, setIsToggled] = React.useState(false);
    const inverseColor = isToggled? "black" : "white";
    const buttonColor = isToggled? "white" : "black";
    const handleClick = () => {
        setIsToggled(!isToggled);
        toggle();
    };

    return (
        <ButtonMenu
            className="buttonMenu"
            whileHover={{ scale: [null, 1, 0.8] }}
            transition={{ duration: 0.3 }}
            onClick={handleClick}
            style={{ backgroundColor: buttonColor }}
        >
            <svg width="23" height="23" viewBox="0 -0.5 20 20">
                <Path
                    variants={{
                        closed: { d: "M 2 2.5 L 18 2.5" },
                        open: { d: "M 3 16.5 L 17 2.5" }
                    }}
                    stroke={inverseColor}
                />
                <Path
                    d="M 2 9.423 L 18 9.423"
                    variants={{
                        closed: { opacity: 1 },
                        open: { opacity: 0 }
                    }}
                    transition={{ duration: 0.1 }}
                    stroke={inverseColor}
                />
                <Path
                    variants={{
                        closed: { d: "M 2 16.346 L 18 16.346" },
                        open: { d: "M 3 2.5 L 17 16.346" }
                    }}
                    stroke={inverseColor}
                />
            </svg>
        </ButtonMenu>
    );
};

const ButtonMenu = styled(motion.nav)`
    right: 2.9166666667vw;
    height: 2.9166666667vw;
    width: 2.9166666667vw;
    min-height: 50px;
    min-width: 50px;
    border: 1px solid #23283e;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
import "./LandingExpert.css";
import React, {useEffect, useRef} from "react";
import { gsap } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function LandingExpert() {
    const notreElement = useRef(null);
    const expertiseElement = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const notreElementCurrent = notreElement.current;
            const expertiseElementCurrent = expertiseElement.current;

            const translateXNotre = `${scrollPosition}px`;
            const translateXExpertise = `${-scrollPosition}px`;

            notreElementCurrent.style.transform = `translateX(${translateXNotre})`;
            expertiseElementCurrent.style.transform = `translateX(${translateXExpertise})`;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [notreElement, expertiseElement]);

    let landingExpertImg = useRef();
    gsap.registerPlugin(CSSRulePlugin);
    let brown = CSSRulePlugin.getRule(".landingExpertContainer::after");

    let landingExpertAnim = gsap.timeline();
    gsap.registerPlugin(ScrollTrigger);

    function animate() {
        window.scrollTo(0, 0);
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".landingExpertContainer",
                start: "top top",
                end: "bottom top",
                scrub: true,
            },
        });
        const depth = landingExpertImg.current.dataset.depth;
        const movement = landingExpertImg.current.offsetHeight / depth;
        tl.to(landingExpertImg.current, { y: movement });

        landingExpertAnim
            .to(".titleContainer span", {
                opacity: 1,
                y: 0,
                stagger: 0.05,
            })
            .to(brown, 0.2, {
                cssRule: {
                    scaleY: 0,
                },
                ease: "circ.in",
            })
            .fromTo(
                ".imgContainer",
                1.5,
                {
                    width: "18%",
                    height: "50%",
                },
                {
                    width: "100%",
                    height: "100%",
                    ease: "expo.inOut",
                }
            )
            .to(
                ".text",
                1,
                {
                    opacity: 1,
                },
                "<"
            )
            .delay(0.5);
    }

    return (
        <section className="landingExpertContainer">
            <div className="imgContainer">
                <img
                    onLoad={animate}
                    ref={landingExpertImg}
                    src="/img/service/img11.jpg"
                    alt="x"
                    data-depth="2.5"
                />
            </div>
            <div className="titleContainer">
                <span>
                    <div ref={notreElement}>Notre</div>
                    <div ref={expertiseElement} style={{letterSpacing:'15px'}}>Expertise</div>
                </span>
            </div>

        </section>
    );
}



export default LandingExpert;
import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { motion, useScroll, useTransform } from 'framer-motion';
import LearnMore from '../../../components/LearnMore';
import styled from 'styled-components';
import '../../../styles/ServiceBlock.css'

const ServiceBlock = ({ image, digit, heading, text, link} ) => {
    const containerRef = useRef(null);
    const animatedDivRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end start"]
    });
    const translateY = useTransform(
        scrollYProgress,
        [0, 1],
        ["10%", "-25%"]
    );

    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const animationDuration = 1; // 1 second animation duration
        const animationDirection = digit % 3 === 0? 'right' : 'left';

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && setIsAnimated !== true) {
                        gsap.fromTo(
                            animatedDivRef.current,
                            { x: animationDirection === 'right'? 100 : -100, opacity: 0 },
                            { x: 0, opacity: 1, duration: animationDuration, ease: 'power2.inOut' }
                        );
                    }
                });
            },
            { threshold: 0.5 }
        );
        observer.observe(animatedDivRef.current);
    }, [digit]);

    return (
        <div className="service-block" ref={containerRef}>
            <a href={link}>
                <motion.img
                    className="image"
                    style={{
                        backgroundImage: `url(${image})`,
                        translateY: translateY,
                    }}
                    whileHover={{scale: 1.05}}
                />
            </a>
            <div ref={animatedDivRef} style={{zIndex: "10", opacity: isAnimated ? 1 : 0}}>
                <ServiceBlockDigit>{digit}</ServiceBlockDigit>
                <ServiceBlockHeadings className="headings">
                    <ServiceHeading href={link} >
                        {heading}
                    </ServiceHeading>
                    <ServiceBlockHeadingsAfter className="signes" />
                </ServiceBlockHeadings>
                <ServiceBlockText >{text}</ServiceBlockText>
                <LearnMore href={link} />
            </div>
        </div>
    );
};

const ServiceBlockDigit = styled.div`
    margin-bottom: 4vw;
    font-size: max(1vw, min(17px));
    font-weight: 400;
    letter-spacing: 0vw;
    line-height: 2vw;
    @media (max-width: 599px) {
        margin-top: 100vw;
    }
`;

const ServiceHeading = styled.a`
    text-Decoration: none;
    color: #060606;

    &:hover::after {
        width: 100%;
    }

    &:hover {
        color: #d91a27;
    }
`

const ServiceBlockHeadings = styled.h3`
    font-size: max(3vw, min(30px));
    font-weight: 400;
    letter-spacing: 0vw;
    line-height: max(3vw, min(30px));
`;

const ServiceBlockHeadingsAfter = styled.span`
    border-bottom: 1px solid #060606;
    display: flex;
    margin: 1vw 0 2vw;
    width: 23vw;
`;

const ServiceBlockText = styled.p`
    margin-bottom: 2vw;
    width: 23vw;
    font-size: max(1vw, min(17px));
    font-weight: 400;
    letter-spacing: 0vw;
    @media (max-width: 599px) {
        width: 90vw;
    }
`;

export default ServiceBlock;
import React, {useContext} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AnimatedCursor from "react-animated-cursor";
import { Stack } from "@mui/material";
import FirstSection from "./section/FirstSection";
import { ThemeContext } from "../../styles/theme";
import Team from "./section/Team";
import Companies from "./section/Companies";
import Footer from "../Footer";
import ContactUs from "./section/ContactUs";
import SecondSection from "./section/SecondSection";
import Preloader from "../../Preloader/Preloader";
import Menu from "../Menu";

const Home = () => {
    const [{ themeName }] = useContext(ThemeContext);

    return (
        <div className="App">
            <Menu textColor={true}/>
            <React.Fragment>
                <CssBaseline />
                <AnimatedCursor
                    outerStyle={{
                        border: "1px solid var(--cursor-color)",
                    }}
                    innerStyle={{
                        backgroundColor: "var(--cursor-color)",
                    }}
                    innerSize={8}
                    outerSize={35}
                    innerScale={1}
                    outerScale={2}
                    outerAlpha={0}
                    hasBlendMode={true}
                />
                <Stack>
                    <div style={{width: "100%", height: "100vh"}}
                         className={`${themeName} app`}>
                        <section id={'1'}>
                            <FirstSection/>
                        </section>
                        <section id={'2'} >
                            <SecondSection/>
                        </section>
                        <section id={"4"} >
                            <Team/>
                        </section>
                        <section id={'5'} >
                            <Companies/>
                        </section>
                        <section id={'6'}>
                            <ContactUs/>
                        </section>
                        <section id={'7'}>
                            <Footer/>
                        </section>
                    </div>
                </Stack>
            </React.Fragment>
            <Preloader/>
        </div>
    );
}

export default Home;

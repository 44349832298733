import React from "react";
import styled from "styled-components";

const Banner = () => {
    return (
        <>
            <ContactUsBanner>
                <BannerHeadding>
                    <h1>Restons Connectés</h1>
                </BannerHeadding>
                <BannerTitle>
                    Nous serions ravis de vous aider !
                </BannerTitle>
                <VideoOverlay>
                    <video autoPlay loop muted>
                        <source src="/video/Video-test.mp4" type="video/mp4" />
                    </video>
                </VideoOverlay>
            </ContactUsBanner>
        </>
    );
};

const ContactUsBanner = styled.div`
    height: 500px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   position: relative;
    overflow: hidden;
    width: 100%;

`;

const BannerHeadding = styled.span`
    font-size: 35px;
    font-weight: 500;
    text-align: center;
    z-index: 1;
`;

const BannerTitle = styled.span`
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.20em;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  video {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`;

export default Banner;
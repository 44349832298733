import ButtonDrawer from "../components/drawerMenu/ButtonDrawer";
import React, { useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import { motion } from "framer-motion";
import styled from 'styled-components';
import DrawerMenu from "../components/drawerMenu/DrawerMenu";


const Menu = ({ history , textColor }) => {
    const h3Ref = React.useRef(null);
    const logoRef = React.useRef(null);
    const imgRef = React.useRef(null);
    const image = '/img/suisse.png';

    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            const h3Element = h3Ref.current;
            const logoElement = logoRef.current;
            const imgElement = imgRef.current;

            const handleScroll = (e) => {
                if (menuOpen) {
                    e.preventDefault();
                }
            };

            const handleBodyClick = (e) => {
                if (e.target === document.body) {
                    setMenuOpen(false);
                }
            };

            if (!menuOpen) {
                document.body.style.overflow = 'auto';
                document.body.style.paddingRight = '0px';
                window.removeEventListener('scroll', handleScroll);
                document.body.removeEventListener('click', handleBodyClick);
                if (scrollPosition > 0 && h3Element.style.transform!== 'translateY(-100%)') {
                    h3Element.style.transform = 'translateY(-100%)';
                } else if (scrollPosition === 0 && h3Element.style.transform!== 'translateY(0)') {
                    h3Element.style.transform = 'translateY(0)';
                }
                if (scrollPosition > 0 && logoElement.style.transform!== 'translateY(-100%)') {
                    logoElement.style.transform = 'translateY(-100%)';
                } else if (scrollPosition === 0 && logoElement.style.transform!== 'translateY(0)') {
                    logoElement.style.transform = 'translateY(0)';
                }
                if (scrollPosition > 0 && imgElement.style.transform!== 'translateX(-550%)') {
                    imgElement.style.transform = 'translateX(-550%)';
                } else if (scrollPosition === 0 && imgElement.style.transform!== 'translateX(0)') {
                    imgElement.style.transform = 'translateX(0)';
                }
            }
            else {
                document.body.style.overflow = 'hidden';
                document.body.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
                window.addEventListener('scroll', handleScroll);
                document.body.addEventListener('click', handleBodyClick);
                h3Element.style.transform = 'translateY(0)';
                logoElement.style.transform = 'translateY(0)';
                imgElement.style.transform = 'translateX(0)';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = '0px';
            window.removeEventListener('scroll', handleScroll);
        };
    }, [menuOpen]);

    //MENU OPEN
    const [state, setState] = useState({
        initial: false,
        clicked: null,
        menuName: "Menu"
    });
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        history.listen(() => {
            setState({ clicked: false, menuName: "Menu" });
        });
    }, [history]);

    // Toggle menu
    const handleMenu = () => {
        disableMenu();
        if (state.initial === false) {
            setState({
                initial: null,
                clicked: true,
                menuName: "Close"
            });
            setMenuOpen(true);
        } else if (state.clicked === true) {
            setState({
                clicked: !state.clicked,
                menuName: "Menu"
            });
            setMenuOpen(false);
        } else if (state.clicked === false) {
            setState({
                clicked: !state.clicked,
                menuName: "Close"
            });
            setMenuOpen(true);
        }
    };

    const disableMenu = () => {
        setDisabled(!disabled);
        setTimeout(() => {
            setDisabled(false);
        }, 1200);
    };

    return (
        <div>
            <Container>
                <LogoContainer>
                    <Logo ref={logoRef}
                          style={{
                              transition: 'transform 0.5s ease-out, opacity 0.3s ease-out',
                              color: textColor ? "black" : "white",
                          }}>

                        Swisseva
                    </Logo>
                </LogoContainer>
                <a href={"/"}>
                    <LogoImage ref={imgRef} style={{
                        backgroundImage: `url(${image})`,
                    }}/>
                </a>
                <MenuContainer>
                    <H3Container>
                        <H3 ref={h3Ref} style={{
                            margin: 'auto',
                            width: 50,
                            transition: 'transform 0.5s ease-out, opacity 0.3s ease-out',
                            zIndex: 1,
                            color: textColor ? "black" : "white",
                        }}>{`Menu`}</H3>
                    </H3Container>
                    <ButtonContainer
                        initial={{opacity: 0, scale: 0.5}}
                        animate={{opacity: 1, scale: 1}}
                        transition={{
                            duration: 0.8,
                            delay: 0.5,
                            ease: [0, 0.71, 0.2, 1.01]
                        }}
                        disabled={disabled} onClick={handleMenu}
                    >
                        <ButtonDrawer/>
                    </ButtonContainer>
                </MenuContainer>
            </Container>
            <DrawerMenu state={state}/>
        </div>

    )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 3vw;
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
`;

const LogoContainer = styled.div`
  overflow: hidden;
  margin-right: 0.7vw;
  align-items: start;
`;

const Logo = styled(motion.div)`
  font: 2rem 'DeckerB', sans-serif;
    font-size: 45px;
    &:hover {
        transform: scale(0.9);
    }
    @media (max-width: 950px) {
        font-size: 43px;
        margin-right: 12px;
    }
    @media (max-width: 400px) {
        font-size: 1.75em;
    }
`;

const LogoImage = styled(motion.img)`
    width: 35px;
    height: 35px;
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in-out;
    border: none;
    &:hover {
        transform: scale(0.9);
    }
`;

const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    position: relative;
`;

const H3Container = styled.div`
    width: 100px;
    height: 40px;
    overflow: hidden;
    margin-right: 1vw;
    align-items: start;
`;

const H3 = styled(motion.h3)`
    font-size: 1.75em;
    transition: transform 0.5s ease-out, opacity 0.3s ease-out;
    z-index: 1;
`;

const ButtonContainer = styled(motion.div)`

`;

export default  withRouter(Menu);


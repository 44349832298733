import styled, { keyframes } from "styled-components";
import React from "react";

const FirstExpert = () => {
    return (
        <Section>
            <div>
                <Sticky>
                    <Img>
                        <img src={"img/service/img7.jpg"} alt="ExpertImage" />
                    </Img>
                </Sticky>
                <Who>Expertise</Who>
                <Title>Financier Investissement Société</Title>
                <Text>
                    Bienvenue chez Swisseva, votre partenaire de confiance pour la création et
                    la gestion d'entreprises en Suisse. Basée à Genève,
                    notre fiduciaire offre une gamme complète de services destinés à soutenir
                    les entrepreneurs et les entreprises dans leurs démarches administratives,
                    financières, stratégiques et digitales.
                    Grâce à notre expertise locale et notre approche personnalisée,
                    nous vous aidons à transformer vos idées en succès.
                </Text>
                <ImgMobile>
                    <img src={"img/service/img7.jpg"} alt="ExpertImage" />
                </ImgMobile>
            </div>
        </Section>
    );
};

const Section = styled.section`
    width: 45%;
    color: black;
    padding: 7% 4%;
    z-index: 2;
    @media (max-width: 950px) {
        width: 100%;
    }
    
`;

const Who = styled.h3`
    font-size: calc(0.8rem + 1vw);
    font-weight: 400;
    text-transform: uppercase;
    padding-bottom: 1rem;
    letter-spacing: 3px;
`;

const Title = styled.h2`
    font-size: calc(2rem + 3vw);
    padding-bottom: 1.5rem;
`;

const Text = styled.p`
    font-size: max(1vw, min(16px));
`;

const Img = styled.div`
  img {
    position: absolute;
    top: 10%;
    margin-left: 55vw;
    height: 50vw;
      max-height: 50vh;
    width: 35vw;
      max-width: 1500px;
    z-index: -1;
    transition: transform 1.0s ease-out, opacity 0.3s ease-out;
  }
`;
const ImgMobile = styled.div`
  img {
    top: 10%;
    height: 50vh;
    z-index: -1;
    transition: transform 1.0s ease-out, opacity 0.3s ease-out;
    position: relative;
    margin-left: 0;
    width: 100%;
  }
    @media (min-width: 950px) {
        display: none;
    }
`;


const fadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-100vh);
    }
    100% {
        opacity: 1;
        transform: translateY(-80vh);
    }
`;

const Sticky = styled.div`
    top: 120%;
    position: sticky;
    transform: translateY(-80vh);
    opacity: 0;
    transition: transform 1.0s ease-out, opacity 0.3s ease-out;
    animation: ${fadeIn} 1.5s forwards;
    animation-delay: 1.8s;

    @media (max-width: 950px) {
        display: none;
    }
`;

export default FirstExpert;
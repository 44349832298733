import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export let animation = () => {
    gsap.from(".box1", {
        scrollTrigger: {
            trigger: ".box1",
            start: "center bottom",
            end: "center bottom",
            toggleActions: "play none reverse none"
        },
        x: "-100%",
        opacity: 0,
        duration: 1
    });

    gsap.from(".box2", {
        scrollTrigger: {
            trigger: ".box2",
            start: "center bottom",
            end: "center bottom",
            toggleActions: "play none reverse none"
        },
        x: "-50%",
        opacity: 0,
        duration: 1
    });
    gsap.from(".box3", {
        scrollTrigger: {
            trigger: ".box3",
            start: "center bottom",
            end: "center bottom",
            toggleActions: "play none reverse none"
        },
        x: "50%",
        opacity: 0,
        duration: 1
    });
    gsap.from(".box4", {
        scrollTrigger: {
            trigger: ".box4",
            start: "center bottom",
            end: "center bottom",
            toggleActions: "play none reverse none"
        },
        x: "100%",
        opacity: 0,
        duration: 1
    });
};

// OPEN MENU
export const staggerReveal = (node1, node2) => {
    gsap.from([node1, node2], {
        duration: 0.8,
        height: 0,
        transformOrigin: "right top",
        skewY: 2,
        ease: "power3.inOut",
        stagger: {
            amount: 0.1
        }
    });
};

// CLOSE MENU
export const staggerRevealClose = (node1, node2) => {
    gsap.to([node1, node2], {
        duration: 0.8,
        height: 0,
        ease: "power3.inOut",
        stagger: {
            amount: 0.07
        }
    });
};

// Fade up for the additonal info on our menu
export const fadeInUp = node => {
    gsap.from(node, {
        y: 60,
        duration: 1,
        delay: 0.2,
        opacity: 0,
        ease: "power3.inOut"
    });
};


import React from "react";
import {Grid,
} from "@mui/material";
import { MdAddCall } from "react-icons/md";
import styled from "styled-components";

const ContactUsForm = () => {
    return (
        <>
            <ContactUform>
                <Grid container spacing={8}
                      direction="column"
                      justifyContent="center"
                      alignItems="center">
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        Mobile & WhatsApp business:
                        <br/>
                        <Phone /><PhoneContact href="tel:+41767860222">+41767860222</PhoneContact>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        Standard:
                        <br/>
                        <Phone/><PhoneContact href="tel:+41227313131">+41227313131</PhoneContact>
                    </Grid>
                </Grid>
            </ContactUform>
        </>
    );
};

const PhoneContact = styled.a`
    color: #212121;
    text-decoration: none;
    font-size: 30px;
    &::before {
        margin-left: auto;
    }
    &::after {
        content: '';
        width: 0%;
        height: 2px;
        background: #d91a27;
        display: block;
        transition: 0.5s;
    }
    &:hover::after {
        width: 100%;
    }
    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`

const ContactUform = styled.div`
    align-items: center;
    display: flex;
    align-content: center;
    width: 100%;
`

const Phone = styled(MdAddCall)`
    transition: all 0.3s;
    width: 30px;
    height: 30px;
    margin-right: 8px;
    margin-bottom: -6px;
    ${PhoneContact}:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

export default ContactUsForm;
import React, { useEffect} from "react";
import styled from 'styled-components';


function VideoFirst() {
    const videoElement = document.getElementById('VideoFirst');

    const thirdStep = () => {
        videoElement.style.marginTop = '0';
        videoElement.style.marginLeft = '0';
        videoElement.style.width = '100%';
        videoElement.style.height = '100%';
        videoElement.style.zIndex = 1;
        document.body.style.overflow = 'auto';
        document.body.style.pointerEvents = 'auto';
    }

    const secondStep = ({top, left}) => {
        videoElement.style.marginTop = `calc(-${Math.round(top)}px + 2.5vh)`;
        videoElement.style.marginLeft = `calc(-${Math.round(left)}px + 2.5vw)`;
        videoElement.style.width = '95vw';
        videoElement.style.height = '95vh';
        videoElement.style.transition = 'all 0.7s ease-in-out';

        setTimeout(() => {
            thirdStep();
        }, 2000);
    };

    const firstStep = ({top, left}) => {
        videoElement.style.marginTop = `-${Math.round(top)}px`;
        videoElement.style.marginLeft = `-${Math.round(left)}px`;
        videoElement.style.width = '100vw';
        videoElement.style.height = '100vh';
        videoElement.style.zIndex = 1000;
        document.body.style.overflow = "hidden";
        document.body.style.pointerEvents = "none";

        setTimeout(() => {
            secondStep({top, left});
        }, 4000);
    };

    const getOffsets = () => {
        const offsets = document.getElementById('VideoFirst').getBoundingClientRect();

        if (offsets.top > 0 && offsets.left > 0) {
            firstStep({ top: offsets.top, left: offsets.left + 9 });
        }
    };

    useEffect(() => {
        if (videoElement) {
            getOffsets();
        }
    }, [getOffsets, videoElement]);

    return (
        <Media>
            <div style={{
                width: '100%',
                height: '100%',
            }} />
            <div
                style={{
                    marginLeft:'-100%',
                    width: '100%',
                    height: '100%'
                }}>
                <VideoContainer
                    id='VideoFirst'
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                >
                    <video
                        autoPlay
                        loop
                        muted
                        style={{
                            zIndex: 1,
                            marginLeft: 0,
                            marginTop: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }}
                    >

                        <source src="/video/Video-test.mp4" type="video/mp4"/>
                    </video>
                    <a href="https://www.youtube.com/@Swisseva_ch">
                        <Wrapper >
                            <Icon className="icon">
                                <svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path>
                                    <path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path>
                                    <path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path>
                                </svg>
                            </Icon>
                            <Text1>Suivez-nous</Text1>
                            <Text2 className="text2">1,2k</Text2>
                        </Wrapper>
                    </a>

                </VideoContainer>
            </div>
        </Media>
    )
}

const Wrapper = styled.button`
  position: fixed;
  width: 133px;
  height: 35px;
    margin-top: 128%;
  margin-left: -150px;
  background-color: white;
  border: none;
  overflow: hidden;
    @media (max-width: 950px) {
        margin-top: 600px;
        margin-bottom: 50px;
    }

  &:hover .icon {
    transform: translateX(96px);
    transition: transform 0.5s;
  }

  &:hover .text2 {
    transform: translateX(100px);
    transition: transform 0.6s;
  }

  &:active {
    transform: scale(1.03);
  }
`;

const Icon = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s;

  &::before {
    position: absolute;
    left: -100px;
    top: 0;
    z-index: -1;
    content: '';
    width: 137px;
    height: 35px;
    background-color: #d91a27;
  }
`;

const Text1 = styled.span`
  font-size: 15px;
  font-weight: 600;
  margin-left: 27%;
`;

const Text2 = styled.span`
  position: absolute;
  top: 25%;
  left: -50px;
  font-weight: 700;
  font-size: 14px;
  color: white;
`;


const Media = styled.div`
    opacity: 1;
    display: flex;
    flex-direction: row;
    height: 62vw;
    align-items: flex-start;
    transition: all 0.5s ease-in-out;
    @media (max-width: 950px) {
        height: 650px;
        margin-bottom: 50px;
    }
`;

const VideoContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
`;


export default VideoFirst;
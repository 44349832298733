import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { styled } from "styled-components";
import {
    staggerReveal,
    fadeInUp,
    staggerRevealClose
} from "../../styles/animations";
import {FaFacebook, FaInstagram, FaLinkedinIn, FaTiktok, FaYoutube} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const DrawerMenu = ({ state }) => {
    let menuLayer = useRef(null);
    let reveal1 = useRef(null);
    let reveal2 = useRef(null);

    useEffect(() => {
        // If the menu is open and we click the menu button to close it.
        if (state.clicked === false) {
            // If menu is closed and we want to open it.
            staggerRevealClose(reveal2, reveal1);
            // Set menu to display none
            gsap.to(menuLayer, { duration: 1, css: { display: "none" } });
        } else if (
            state.clicked === true ||
            (state.clicked === true && state.initial === null)
        ) {
            // Set menu to display block
            gsap.to(menuLayer, { duration: 0, css: { display: "block" } });
            //Allow menu to have height of 100%
            gsap.to([reveal1, reveal2], {
                duration: 0,
                opacity: 1,
                height: "100%"
            });
            staggerReveal(reveal1, reveal2);
            fadeInUp();
        }
    }, [state]);

    return (
        <HamburgerMenu ref={el => (menuLayer = el)}>
            <MenuSecondaryBackgroundColor ref={el => (reveal1 = el)} />
            <MenuLayer ref={el => (reveal2 = el)}>
                <Container className="container menu">
                    <Nav>
                        <ul>
                            <li>
                                <a href="/">Accueil</a>
                            </li>
                            <li>
                                <a href="/Service">Services</a>
                            </li>
                            <li>
                                <a href="/expertise">Expertise</a>
                            </li>
                        </ul>
                    </Nav>
                    <SocialDiv>
                        <SocialA href="https://www.youtube.com/@Swisseva_ch">
                            <Youtube/>
                        </SocialA>
                        <SocialA href="https://www.facebook.com/" >
                            <Facebook/>
                        </SocialA>
                        <SocialA href="https://twitter.com/swisseva_ch" >
                            <Twitter/>
                        </SocialA>
                        <SocialA href="https://www.instagram.com/swisseva_geneve/" >
                            <Instagram/>
                        </SocialA>
                        <SocialA href="https://www.LinkedinIn.com/" >
                            <LinkedinIn/>
                        </SocialA>
                        <SocialA href="https://www.tiktok.com/@swisseva_ch" >
                            <Tiktok/>
                        </SocialA>
                    </SocialDiv>
                </Container>
            </MenuLayer>
        </HamburgerMenu>
    );
};

const SocialDiv =styled.div`
    @media (max-height: 1100px) {
        margin-left: 93%;
        width: 50px;
        margin-top: -80vh;
    }
    @media (max-width: 580px) {
        margin-left: 0;
        width: 100%;
        margin-top: 0;
    }
`

const Container = styled.div`
    margin-top: 10em;
    margin-left: 5vw;
`;

const SocialA = styled.a`
    margin: 1rem;
    transition: transform 250ms;
    display: inline-block;
    color: white;
`

const Nav = styled.nav`
    ul {
        list-style-type: none;
        padding: 0;
    }
    ul li {
        color: white;
        font-size: 10vh;
        text-transform: uppercase;
        overflow: hidden;
        font-weight: 700;
    }
    @media (max-width: 750px) {
        ul li {
            font-size: 10vw;
        }
    }
    a {
        color: white;
        transition: 0.3s;
        text-decoration: none;
    }
    a:hover {
        color: #121212;
        transform: scale(1.5);
    }
`;

const Youtube = styled(FaYoutube)`
    transition: all 0.3s;
    max-height: 50px;
    max-width: 50px;
    min-width: 30px;
    min-height: 30px;

    &:hover {
        color: #121212 !important;
        transform: scale(1.5);
    }
`;

const Facebook = styled(FaFacebook)`
    transition: all 0.3s;
    max-height: 50px;
    max-width: 50px;
    min-width: 30px;
    min-height: 30px;

    &:hover {
        color: #121212 !important;
        transform: scale(1.5);
    }
`;

const Twitter = styled(FaXTwitter)`
    transition: all 0.3s;
    max-height: 50px;
    max-width: 50px;
    min-width: 30px;
    min-height: 30px;
    &:hover {
        color: #121212 !important;
        transform: scale(1.5);
    }
`;

const LinkedinIn = styled(FaLinkedinIn)`
  transition: all 0.3s;
  max-height: 50px;
  max-width: 50px;
  min-width: 30px;
  min-height: 30px;
  &:hover {
    color: #121212 !important;
    transform: scale(1.5);
  }
`;

const Instagram = styled(FaInstagram)`
  transition: all 0.3s;
  max-height: 50px;
  max-width: 50px;
  min-width: 30px;
  min-height: 30px;
  &:hover {
    color: #121212 !important;
    transform: scale(1.5);
  }
`;

const Tiktok = styled(FaTiktok)`
    transition: all 0.3s;
    max-height: 50px;
    max-width: 50px;
    min-width: 30px;
    min-height: 30px;

    &:hover {
        color: #121212 !important;
        transform: scale(1.5);
    }
`;

const HamburgerMenu = styled.div`
  display: none;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 100%;
`;

const MenuSecondaryBackgroundColor = styled.div`
  background: #121212;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

const MenuLayer = styled.div`
  position: relative;
  background: #d91a27;
  height: 100%;
  overflow: hidden;
`;

export default DrawerMenu;
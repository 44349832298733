import styled from 'styled-components';
import "./SecondSection.css"
import React from "react";

const cards = [
    {
        title: "Création de société en Suisse",
        img:"/img/service/img1.jpg",
        copy: "Grâce à notre expertise du marché suisse, nous vous aidons à choisir la bonne structure juridique pour votre projet en Suisse et à gérer les formalités administratives. Nous nous occupons des démarches légales et vous mettons en relation avec des experts si besoin.",
    },
    {
        title: "Ouverture de compte en banque en Suisse",
        img:"/img/service/img2.jpg",
        copy: "Nous facilitons l'ouverture de compte bancaire en Suisse, que ce soit auprès d'une banque privée, digitale ou traditionnelle. Notre conseil personnalisé garantit le succès de votre entreprise. Contactez-nous dès maintenant pour démarrer votre projet sur des bases solides.",
    },
    {
        title: "Résidence Suisse",
        img:"/img/service/img3.jpg",
        copy: "Nous proposons des bureaux partagés à Genève et dans les grandes villes suisses via notre réseau de partenaires. Développez votre activité depuis les espaces de coworking de votre choix et étendez-vous efficacement grâce à notre stratégie de domiciliation d'entreprise.",
    },
    {
        title: "Gestion et comptabilité d'entreprise",
        img:"/img/service/img7.jpg",
        copy: "Optimisez la gestion financière de votre entreprise en Suisse avec notre service de comptabilité sur mesure. Nous gérons vos comptes, la paie et les obligations comptables, vous permettant de vous concentrer pleinement sur le développement de votre activité.",
    },
    {
        title: "Levée de fonds et accompagnement de Startups",
        img:"/img/service/img10.jpg",
        copy: "Accélérez votre startup avec notre soutien personnalisé. Nous vous aidons à concrétiser vos idées grâce à notre expertise et notre réseau. Faites confiance à notre équipe pour atteindre de nouveaux sommets ensemble.",
    },
    {
        title: "Accompagnement d'investisseurs en Suisse",
        img:"/img/service/img6.jpg",
        copy: "Nous vous offrons un accompagnement personnalisé par des experts en conseil en investissement en Suisse, pour optimiser vos rendements financiers selon vos objectifs.",
    }
];

function Card({ title, copy , img}) {
    return (
        <div className="card" style={{ backgroundImage: `url(${img})` }}>
            <div className="content">
                <h2 className="title">{title}</h2>
                <p className="copy">{copy}</p>
            </div>
        </div>
    );
}

const Services = () => {
    return (
        <Container>
            <section className="landing__work">
                <section className='service'>
                    <h2 className='_title'>Nos services
                        <hr className="signes"/>
                    </h2>
                </section>
                <div className="page-content">
                    {cards.map((card, index) => (
                        <Card
                            key={index}
                            title={card.title}
                            copy={card.copy}
                            img={card.img}
                        />
                    ))}
                </div>
            </section>
            <div style={{width: "80vw", margin: "auto", textAlign: 'center'}}>
                <MoreButton href="/Service">
                    <TopKey/>
                    <TextContact>Plus de Services</TextContact>
                    <BottomKey/>
                    <BottomKey2/>
                </MoreButton>
            </div>
        </Container>
    )
        ;
};

const Container = styled.div`
    margin-left: 2vw;
    margin-right: 2vw;
`;

const MoreButton = styled.a`
    margin-top: 100px;
    border: 2px solid #000;
    border-radius: 0;
    position: relative;
    box-sizing: border-box;
    color: #fff;
    display: inline-block;
    padding: 1.25em 2em;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    &::before{
        content: " ";
        width: 1.5625rem;
        height: 2px;
        background: black;
        top: 50%;
        left: 1.5em;
        position: absolute;
        transform: translateY(-50%);
        transform-origin: center;
        transition: background 0.3s linear, width 0.3s linear;
    }
    &:hover {
        color: #d91a27;
        border: 2px solid #d91a27;
    }
    &:hover::before {
        width: 0.9375rem;
        background: #d91a27;
    }
`;

const TextContact = styled.span`
  font-size: 1.125em;
  line-height: 1.33333em;
  padding-left: 2em;
  display: block;
  text-align: left;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  text-decoration: none;
  color: black;

  ${MoreButton}:hover & {
    color: #d91a27;
    padding-left: 1.5em;
  }
`;

const TopKey = styled.span`
  height: 2px;
  width: 1.5625rem;
  top: -2px;
  left: 0.625rem;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, left 0.3s ease-out;

  ${MoreButton}:hover & {
    left: -2px;
    width: 0px;
  }
`;

const BottomKey = styled.span`
  height: 2px;
  width: 1.5625rem;
  right: 1.875rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;

  ${MoreButton}:hover & {
    right: 0;
    width: 0;
  }
`;

const BottomKey2 = styled.span`
  height: 2px;
  width: 0.625rem;
  right: 0.625rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
    ${MoreButton}:hover & {
        right: 0;
        width: 0;
    }
`;

export default Services;
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./main.css";
import Banner from "../components/Banner";
import Loader from "../components/Loader";

export default function LoaderProject() {
    const [loading, setLoading] = useState(true);
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        loading
            ? document.querySelector("body").classList.add("loading")
            : document.querySelector("body").classList.remove("loading");
    }, [loading]);

    return (
        <div style={{height:'100vh'}}>
            <AnimatePresence>
                {loading ? (
                    <motion.div key="loader">
                        <Loader setLoading={setLoading} />
                    </motion.div>
                ) : (
                    <>
                        <Banner />
                        {!loading && (
                            <div className="transition-image final">
                                <motion.img
                                    style={{
                                        y: scrollY * 0.2,
                                    }}
                                    transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.6 }}
                                    src={`img/service/image-2.jpg`}
                                    layoutId="main-image-1"
                                />
                            </div>
                        )}
                    </>
                )}
            </AnimatePresence>
        </div>
    );
}
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Banner.css";

const banner = {
  animate: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1
    }
  }
};

const letterAni = {
  initial: { y: 400 },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1
    }
  }
};

const Banner = () => {
  const [playMarquee, setPlayMarquee] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPlayMarquee(true);
    }, 2000);
  }, []);
  return (
    <motion.div className="banner ">
        <div style={{ marginLeft:"15%"}}>
            <BannerRowTop title={"Nos"} />
        </div>
        <div style={{ marginLeft:"35%"}}>
            <BannerRowCenter title={"Services"} playMarquee={playMarquee} />
        </div>
    </motion.div>
  );
};
const BannerRowTop = ({ title }) => {
  return (
    <div className={"banner-row"}>
      <div className="row-col">
        <AnimatedLetters title={title} />
      </div>
    </div>
  );
};
// come up text
const AnimatedLetters = ({ title, disabled }) => (
  <motion.span
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate"
  >
    {[...title].map((letter) => (
      <motion.span
        className="row-letter"
        variants={disabled ? null : letterAni}
      >
        {letter}
      </motion.span>
    ))}
  </motion.span>
);

const BannerRowCenter = ({ title, playMarquee }) => {
  return (
    <>
      <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
        <motion.div
          initial={{ y: 310 }}
          animate={{ y: 0 }}
          transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1 }}
          className="marquee__inner"
        >
          <AnimatedLetters title={title} />
        </motion.div>
      </div>
    </>
  );
};

export default Banner;

import React from "react";
import styled from "styled-components";
import {
    FaPhone,
} from "react-icons/fa";
import { IoIosPin , IoIosMail } from "react-icons/io"
import {Tooltip} from "@mui/material";


class Social extends React.Component {
    render() {
        const fillColor = this.props.isFull ? "#ffffff" : "#73737d";
        const sizeUp = this.props.isFull ? "2.5vw" : "2vw";
        const leftUp = this.props.isFull ? "2.2rem" : "2.4rem";
        const phone = this.props.isFull ? "2.3vw" : "1.8vw"

        return (
            <SocialBar style={{ left:leftUp}} >
                <Tooltip title="+41 07 67 86 02 22" placement="right">
                    <a href="tel:+41767860222">
                        <Phone size={phone} color={fillColor} />
                    </a>
                </Tooltip >
                <Tooltip title="Pl. de Cornavin 14, 1201 Genève, Suisse" placement="right">
                    <a href="https://maps.app.goo.gl/QWj2oCWaocYdvB5R9">
                        <Pin size={sizeUp} color={fillColor}/>
                    </a>
                </Tooltip>

                <Tooltip title="contact@swisseva.com" placement="right">
                    <a href="mailto:contact@swisseva.com">
                        <Mail size={sizeUp} color={fillColor}/>
                    </a>
                </Tooltip>
            </SocialBar>
        );
    }
}

const SocialBar = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 11rem;
  transition: all 0.3s;
    @media (max-width: 992px) {
        margin-left: -7px;
    }
  @media (max-width: 580px) {
      bottom: 4rem;
      margin-left: -26px;
  }
`;


const Phone = styled(FaPhone)`
  transition: all 0.3s;
  margin-bottom: 5px;
  max-height: 28px;
  max-width: 28px;
  min-width: 20px;
  min-height: 20px;
  &:hover {
    color: #d91a27 !important;
    transform: scale(1.5);
  }
`;

const Pin = styled(IoIosPin)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;
    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Mail = styled(IoIosMail)`
    transition: all 0.3s;
    margin-bottom: -6px;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;
    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;



export default Social;



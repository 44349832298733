import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LearnMoreButton = styled.button`
    border: none;
    background: none;
    cursor: pointer;
    position: relative;
    display: flex;
    font-size: 20px;
    gap: 0.5rem;
    align-items: center;

    &::after {
        position: absolute;
        content: "";
        width: 0;
        left: 0;
        bottom: -7px;
        background: #d91a27;
        height: 2px;
        transition: 0.3s ease-out;
    }

    &:hover::after {
        width: 100%;
    }

    &:hover {
        color: #d91a27;
    }
`;

const PLearnMore = styled.p`
    margin: 0;
    font-size: 20px;
`;

const SVGLearnMore = styled.svg`
    transition: 0.2s;
    position: relative;
    width: 15px;
    transition-delay: 0.2s;

    &:hover {
        transform: translateX(4px);
        color: #d91a27;
    }
`;

const LearnMore = ({ href }) => (
    <a style={{textDecoration: "none"}} href={href}>
        <LearnMoreButton >
            <PLearnMore>En savoir plus</PLearnMore>
            <SVGLearnMore xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="3">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                ></path>
            </SVGLearnMore>
        </LearnMoreButton>
    </a>
);

LearnMore.propTypes = {
    href: PropTypes.string.isRequired,
};

export default LearnMore;
import React from 'react'
import './App.css'
import Home from "./screen/Home/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SocialBar from "./components/SocialBar";
import Service from "./screen/Service/Service";
import Expertise from "./screen/Expertise/Expertise";


function App() {

    return (
        <Router>
            <div className="App">
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route path="/Service" component={ServicePage} />
                        <Route path="/expertise" component={ExertisePage}/>
                    </Switch>
            </div>
        </Router>
    );
}

function HomePage() {
    return (
        <>
            <Home/>
            <SocialBar/>
        </>
    );
}

function ServicePage() {
    return (
        <>
            <Service/>
            <SocialBar/>
        </>
    );
}
function ExertisePage() {
    return (
        <>
            <Expertise/>
            <SocialBar/>
        </>
    );
}


export default App;
import React from "react";
import Banner from "../components/Banner";
import ContactusForm from "../components/ContactusForm";
import styled from "styled-components";

const ContactUs = () => {
    return (
        <div style={{width: "100%"}}>
            <ContainerContact>
                <Text>Nous Contacter
                    <hr/>
                </Text>
            </ContainerContact>
            <ContainerContact>
                <ContactusForm/>
            </ContainerContact>
            <Banner/>
        </div>
    );
};

const Text = styled.h2`
    font-size: max(3vw, min(30px));
    font-weight: 400;
    min-width: 350px;
    letter-spacing: 0vw;
    line-height: 3vw;
    text-align: center;
    width: 40vw;
    margin: auto;
`;

const ContainerContact = styled.section`
    flex-direction: column;
    align-items: center;
    margin-bottom: 90px;
`

export default ContactUs;
import React, {useContext} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AnimatedCursor from "react-animated-cursor";
import Menu from "../Menu";
import { Stack } from "@mui/material";
import { ThemeContext } from "../../styles/theme";
import Transition from "../Transition";
import Footer from "../Footer";
import FirstExpert from "./section/FirstExpert";
import SecondExpert from "./section/SecondExpert";
import LandingExpert from "./section/LandingExpert";
import CompanieExpert from "./section/CompanieExpert";

const Expertise = () => {
    const [{ themeName }] = useContext(ThemeContext);

    return (
        <div className="App" >
            <Menu textColor={false}/>
            <React.Fragment>
                <CssBaseline />
                <AnimatedCursor
                    outerStyle={{
                        border: "1px solid var(--cursor-color)",
                    }}
                    innerStyle={{
                        backgroundColor: "var(--cursor-color)",
                    }}
                    innerSize={8}
                    outerSize={35}
                    innerScale={1}
                    outerScale={2}
                    outerAlpha={0}
                    hasBlendMode={true}
                />
                <Stack>
                    <div style={{width: "100%", height: "100vh"}}
                         className={`${themeName} app`}>
                        <section>
                            <LandingExpert/>
                        </section>
                        <section id={'2'}>
                            <FirstExpert/>
                        </section>
                        <section id={"3"}>
                            <SecondExpert/>
                        </section>
                        <section id={"5"}>
                            <CompanieExpert/>
                        </section>
                        <section id={'7'}>
                            <Footer/>
                        </section>
                    </div>
                </Stack>
            </React.Fragment>
        </div>
    );
}

export default Transition(Expertise);

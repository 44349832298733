import React  from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from './styles/theme';
import './i18n';
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

const rootElement = document.getElementById("root");
const root = ReactDOMClient.createRoot(rootElement);

root.render(
  <React.StrictMode>
      <ThemeProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
      </ThemeProvider>
  </React.StrictMode>
);


reportWebVitals();

import React from "react";
import styled, { keyframes, css } from "styled-components";

const imageWidth = 180;

function CompanieExpert() {
    const row1 = [
        "img/Company/hotel.jpg",
        "img/Company/crypto.png",
        "img/Company/pogba-logo.JPG",
        "img/Company/tiny.png",
    ];

    const row2 = [
        "img/Company/swisssafe.JPG",
        "img/Company/soccer.jpg",
        "img/Company/SPI.png",
        "img/Company/Tanzania.png",
        "img/Company/up.JPG",
    ];

    const numberGroups = Math.round((window.innerWidth / (imageWidth * row1.length)) + 1);

    return (
        <AppContainer>
            <Wrapper>
                <Note>Une expertise globale dans l'innovation !</Note>
                <Marquee>
                    {[...Array(numberGroups).keys()].map((_, i) => (
                        <MarqueeGroup key={`marquee1-${_}`}>
                            {row1.map((el) => (
                                <ImageGroup>
                                    <Image src={el}/>
                                </ImageGroup>
                            ))}
                        </MarqueeGroup>
                    ))
                    }
                </Marquee>
                <Marquee>
                    {[...Array(numberGroups).keys()].map((_, i) => (
                        <MarqueeGroup2  key={`marquee2-${_}`}>
                            {row2.map((el) => (
                                <ImageGroup>
                                    <Image src={el}/>
                                </ImageGroup>
                            ))}
                        </MarqueeGroup2>
                    ))
                    }
                </Marquee>
            </Wrapper>
        </AppContainer>
    )
        ;
}

export default CompanieExpert;

const AppContainer = styled.div`
    height: 80vh;
    color: #000000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wrapper = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Note = styled.div`
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 40px;
  text-align: center;
`;

const Marquee = styled.div`
  display: flex;
  width: 85vw;
  overflow: hidden;
  user-select: none;

  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;

const scrollX = keyframes`
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 30s linear infinite;
    min-width: fit-content;
`;

const MarqueeGroup = styled.div`
  ${common}
`;
const MarqueeGroup2 = styled.div`
  ${common}
  animation-direction: reverse;
  animation-delay: -3s;
    margin-top: 16px;

`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
`;

const Image = styled.img`
  object-fit: contain;
  width: 180px;
  height: 180px;
    margin-left: 36px;
  padding: 5px 20px;
`;
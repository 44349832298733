import styled, {keyframes} from "styled-components";
import React from "react";
import { useState } from 'react';
import { motion } from 'framer-motion';
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import Banner from "../../../components/Banner";
import "./ContactExpert.css"
import {Grid} from "@mui/material";
import ButtonContact from "../../../components/ButtonContact";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const data = [
    {
        id: 1,
        desc: "<span style='font-weight: 800; border-bottom: 1px solid #e6e6e6;'>Expertise locale:</span> <br/>Une connaissance approfondie des spécificités économiques, fiscales et réglementaires en Suisse.",
    },
    {
        id: 2,
        desc: "<span style='font-weight: 800; border-bottom: 1px solid #e6e6e6;'>Solutions personnalisées:</span> <br/>Des services sur mesure adaptés aux besoins de chaque client.",
    },
    {
        id: 3,
        desc: "<span style='font-weight: 800; border-bottom: 1px solid #e6e6e6;'>Équipe expérimentée:</span> <br/>Des professionnels qualifiés et expérimentés dans divers domaines d'expertise.",
    },
    {
        id: 4,
        desc: "<span style='font-weight: 800; border-bottom: 1px solid #e6e6e6;'>Réseau de partenaires:</span> <br/>Des relations solides avec les banques, les avocats, les autorités locales et les investisseurs.",
    },
    {
        id: 5,
        desc: "<span style='font-weight: 800; border-bottom: 1px solid #e6e6e6;'>Vision innovante:</span> <br/>Une approche moderne et proactive pour accompagner la transformation digitale et l'optimisation de votre entreprise.",
    },
];

const tabs = [
    'Création et gestion d\'entreprises',
    'Domiciliation d\'entreprises',
    'Gestion comptable et fiscale',
    'Accompagnement en levée de fonds',
    'Digitalisation et innovation',
    'Communication sur les réseaux sociaux',
    'Création de Family Office',
    'Obtention de Résidence et permis de travail',
    'Tokenisation d\'actifs d\'entreprises',
    'Accompagnement d’investisseurs',

];

const content = {
    'Création et gestion d\'entreprises': 'Swisseva vous guide dans toutes les étapes de la création de votre entreprise, de l\'élaboration de votre business plan à l\'enregistrement officiel auprès des autorités suisses. Nous assurons également la gestion administrative et financière de votre entreprise, vous permettant de vous concentrer sur son développement.',
    'Domiciliation d\'entreprises': 'Bénéficiez d\'une adresse prestigieuse à Genève pour domicilier votre entreprise. Nous offrons des services de gestion de courrier et de secrétariat, assurant ainsi une gestion professionnelle de votre correspondance.',
    'Gestion comptable et fiscale': 'Notre équipe de comptables expérimentés prend en charge la tenue de vos livres comptables, la préparation de vos déclarations fiscales et la gestion de la paie. Nous veillons à ce que vos obligations fiscales soient remplies de manière précise et ponctuelle.',
    'Accompagnement en levée de fonds': 'Nous vous aidons à structurer vos levées de fonds, à préparer vos présentations pour les investisseurs et à négocier les termes de votre financement. Notre réseau de contacts vous permet d\'accéder à des investisseurs potentiels et de maximiser vos chances de succès.',
    'Digitalisation et innovation': 'Accélérez la transformation digitale de votre entreprise avec nos solutions innovantes. Nous vous aidons à adopter les meilleures pratiques numériques pour améliorer votre efficacité opérationnelle et votre compétitivité. Nous pilotons la création et le développement de projets digitaux, en assurant une gestion de projet efficace et alignée avec vos objectifs stratégiques.',
    'Communication sur les réseaux sociaux': 'Nous proposons des services de family office pour la gestion de votre patrimoine familial. Notre équipe d\'experts vous aide à structurer, protéger et optimiser vos actifs tout en assurant une gestion fiscale efficace.',
    'Création de Family Office': 'Swisseva sait comment optimiser votre présence en ligne. Nous vous aidons à développer et à exécuter des stratégies de communication sur les réseaux sociaux qui augmentent votre visibilité et engagent votre audience.',
    'Obtention de Résidence et permis de travail': 'Nous vous accompagnons dans toutes les démarches pour l\'obtention de votre résidence et de votre permis de travail en Suisse. Nous vous guidons à travers le processus administratif pour garantir une installation sereine.',
    'Tokenisation d\'actifs d\'entreprises': 'Swisseva vous aide à entrer dans l\'ère de la blockchain avec des services de tokenisation d\'actifs. Transformez vos actifs d\'entreprise en tokens numériques pour faciliter leur gestion et leur échange sur les plateformes de blockchain.',
    'Accompagnement d’investisseurs': 'Nous offrons des services spécialisés pour accompagner les investisseurs dans leurs projets d\'investissement à Genève et ailleurs en Suisse. Nous fournissons des analyses détaillées, des conseils stratégiques et un soutien opérationnel pour maximiser la rentabilité de vos investissements.',
};

const SecondExpert = () => {
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [activeSlide, setactiveSlide] = useState(2);
    const [openAccordionIndex, setOpenAccordionIndex] = useState(0);

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
    };
    const next = () =>
        activeSlide < data.length - 1 && setactiveSlide(activeSlide + 1);

    const prev = () => activeSlide > 0 && setactiveSlide(activeSlide - 1);

    const getStyles = (index) => {
        if (activeSlide === index)
            return {
                opacity: 1,
                transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
                zIndex: 10,
            };
        else if (activeSlide - 1 === index)
            return {
                opacity: 1,
                transform: "translateX(-240px) translateZ(-400px) rotateY(35deg)",
                zIndex: 9,
            };
        else if (activeSlide + 1 === index)
            return {
                opacity: 1,
                transform: "translateX(240px) translateZ(-400px) rotateY(-35deg)",
                zIndex: 9,
            };
        else if (activeSlide - 2 === index)
            return {
                opacity: 1,
                transform: "translateX(-480px) translateZ(-500px) rotateY(35deg)",
                zIndex: 8,
            };
        else if (activeSlide + 2 === index)
            return {
                opacity: 1,
                transform: "translateX(480px) translateZ(-500px) rotateY(-35deg)",
                zIndex: 8,
            };
        else if (index < activeSlide - 2)
            return {
                opacity: 0,
                transform: "translateX(-480px) translateZ(-500px) rotateY(35deg)",
                zIndex: 7,
            };
        else if (index > activeSlide + 2)
            return {
                opacity: 0,
                transform: "translateX(480px) translateZ(-500px) rotateY(-35deg)",
                zIndex: 7,
            };
    };
    return (
        <Section>
            <Titel>
                <Marquee>
                    {[...Array(2).keys()].map((_, i) => (
                        <MarqueeGroup>
                            <a href="https://calendar.app.google/g9PC6S1a3vLsuGu27" style={{
                                textDecoration: "none",
                                color: "white",
                            }}>
                                <Line>
                                    &nbsp;Nous Contacter -
                                </Line>
                            </a>
                        </MarqueeGroup>
                    ))
                    }
                </Marquee>
            </Titel>
            <Container>
                <Title>
                    Suisseva
                </Title>
                <Heading>
                    Notre mission
                </Heading>
                <Text>
                    Chez Swisseva, notre mission est de fournir des solutions innovantes et
                    sur mesure pour répondre aux besoins spécifiques de chaque client.
                    Nous nous engageons à offrir un service de qualité, basé sur l'écoute,
                    la réactivité et la confidentialité.
                    Notre objectif est de vous accompagner à chaque étape de votre parcours entrepreneurial,
                    en vous offrant des conseils avisés et des solutions pratiques.

                    <br/>
                </Text>
                <Tabs>
                    <Heading>
                        Notre expertise
                    </Heading>
                    <ContainerTabs>
                        <div>
                            {tabs.map((tab) => (
                                <Tab
                                    key={tab}
                                    className={selectedTab === tab ? 'active' : ''}
                                    onClick={() => setSelectedTab(tab)}
                                >
                                    {tab}
                                </Tab>
                            ))}
                        </div>
                        <Content>
                            <Heading>
                                {[selectedTab]} :
                            </Heading>
                            <motion.div
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.5}}
                            >
                                {content[selectedTab]}
                            </motion.div>
                        </Content>
                    </ContainerTabs>
                    <MobileContainerTabs>
                        <div>
                            {Object.entries(content).map(([title, description], index) => (
                                <Accordion key={title} expanded={index === openAccordionIndex}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls={`panel-${title}-content`}
                                        id={`panel-${title}-header`}
                                        onClick={() => handleAccordionClick(index)}
                                        style={{borderBottom:"1px solid #e6e6e6"}}
                                    >
                                        <HeadingMobile >{title}:</HeadingMobile>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>{description}</p>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    </MobileContainerTabs>
                </Tabs>
                <Heading style={{marginTop: "50px"}}>
                    Pourquoi choisir Swisseva ?
                </Heading>
            </Container>
            <div className="slideC">
                {data.map((item, i) => (
                    <React.Fragment key={item.id}>
                        <div
                            className="slide"
                            style={{
                                background: item.bgColor,
                                boxShadow: `0 5px 20px ${item.bgColor}30`,
                                ...getStyles(i),
                            }}
                        >
                            <div className="sliderContent" dangerouslySetInnerHTML={{__html: item.desc}}/>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <div className="btns">
                <a><FaChevronLeft className="btn" onClick={prev} size={"20px"}/></a>
                <a><FaChevronRight className="btn" onClick={next} size={"20px"}/></a>
            </div>
            <br/>
            <Banner
                title="Contactez-nous"
                videoSrc="/video/Video-test.mp4"
                bannerTitle={
                    <>
                        <p>Pour en savoir plus sur nos services ou pour discuter de vos besoins spécifiques, n'hésitez
                            pas à nous contacter.</p>
                        <p>Chez Swisseva, nous sommes toujours prêts à vous offrir des solutions personnalisées et
                            efficaces.</p>
                        <p>Nous vous invitons à nous rejoindre pour construire ensemble l'avenir de votre
                            entreprise.</p>
                        <br/>
                        <Grid item xs={8}>
                            <ButtonContact borderColor="#fff" textColor="#fff" keyColor="transparent"/>
                        </Grid>
                    </>
                }
            />
        </Section>
    );
};


const Container = styled.div`
    margin-left: 2vw;
    margin-right: 2vw;
    display: grid;
    grid-column-gap: 2vw;
    grid-template-columns: repeat(16, 1fr);
    align-items: flex-start;
    @media (max-width: 950px) {
        padding: 14vw 0 0;
        margin-left: 2vw;
        margin-right: 2vw;
        display: grid;
        grid-column-gap: 2vw;
        grid-template-columns: repeat(16, 1fr);
        align-items: flex-start;
    }
`;

const Title = styled.h1`
    font-style: italic;
    font-weight: 400;
    grid-column: 3 / 15;
`

const Heading = styled.h2`
    grid-column: 3 / 15;
    margin: 2vw 0 5vw;
    font-weight: 800;
`

const HeadingMobile = styled.h2`
    font-weight: 600;
    margin: 2vw 0 2vw;
    font-size: max(3vw, min(16px));
`

const Tabs = styled.div`
    grid-column: 3 / 15;
    margin: 2vw 0 5vw;
`

const Text = styled.p`
    font-size: max(1vw, min(16px));
    grid-column: 3 / 15;
    @media (max-width: 599px) {
        grid-column: 3 / 15;
    }
`

const Section = styled.section`
    overflow: hidden;

    h4 {
        width: 300%;
        font-size: calc(2rem + 3vw);
        font-weight: 600;
        color: ${({theme}) => theme.lightgrey};
        text-transform: uppercase;
        letter-spacing: 5px;
        margin-right: -20%;
    }
`;

const Titel = styled.h2`
    margin-left: 5vw;
`

const scrollX = keyframes`
    to {
        transform: translateX(-100%);
    }
`

const MarqueeGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;
    width: 100%;
    animation: ${scrollX} 15s linear infinite;
    min-width: fit-content;
`

const Marquee = styled.div`
    display: flex;
    width: 85vw;
    overflow: hidden;
    user-select: none;

    mask-image: linear-gradient(
            to right,
            hsl(0 0% 0% / 0),
            hsl(0 0% 0% / 1) 10%,
            hsl(0 0% 0% / 1) 90%,
            hsl(0 0% 0% / 0)
    );
`

const Line = styled.div`
    font-size: 10vw;
    color: #121212;
`

const ContainerTabs = styled.div`
    display: flex;
    gap: 20px;
    
    @media (max-width: 700px) {
        display: none;
    }
`;

const MobileContainerTabs = styled.div`
    display: none;
    @media (max-width: 700px) {
        display: flex;
    }
`

const Tab = styled.div`
    border-bottom: 1px solid #e6e6e6;
    letter-spacing: 3px;
    line-height: 1.135;
    color: #c2c2c2;
    margin: 0;
    padding-bottom: 1.125rem !important;
    padding-top: 1.125rem !important;
    text-transform: uppercase;
    font-size: max(1vw, min(16px));

    &:hover {
        font-weight: 800;
        color: #333333;
    }

    &.active {
        opacity: 1;
        font-weight: 800 !important;
        color: #333333 !important;
    }
`;

const Content = styled.div`
    font-size: max(1vw, min(16px));
    flex: 1;
    padding: 20px;
    border-radius: 5px;
`;

export default SecondExpert;
